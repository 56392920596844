import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const InformationAboutLupus = () => (
	<Layout>
		<SEO title="What is lupus?" />
		<h1>What is lupus?</h1>
		<h2>Overview</h2>
		<p>
			Lupus is a chronic, complex and prevalent autoimmune disease that affects
			around 1 in 600 people in Australia. However, we believe this figure to be
			much higher as there are many people who are unreported and alarmingly
			many remain undiagnosed.
		</p>
		<p>
			Lupus is difficult to diagnose because its symptoms are so wide-ranging,
			can be transient and often mimic those of other diseases. Additionally,
			there is no single laboratory test that can definitively identify that
			someone has lupus.
		</p>
		<h2>Types of Lupus</h2>
		<p>
			Although “lupus” is used as a broad term, there are four different types
			of lupus:
		</p>
		<ol>
			<li>
				<span className="font-bold">Systemic Lupus Erythematosus (SLE).</span>
				<br />
				<span>
					This is the form of the disease that most people refer to when they
					say “lupus” (and what this guide is focused on). The word “systemic”
					means the disease can affect many parts of the body — including the
					kidneys, brain or central nervous system, blood and blood vessels,
					skin, lungs, heart and joints. Skin involvement occurs in up to 80% of
					patients.
				</span>
			</li>
			<li>
				<span className="font-bold">
					Discoid or cutaneous lupus affecting only the skin.
				</span>
				<br />
				<span>
					As the name suggests, this affects the skin. Discoid lupus causes a
					red, scaly rash with raised borders particularly on areas of the body
					that are exposed to the sun (something to be especially mindful of in
					Australia).
				</span>
			</li>
			<li>
				<span className="font-bold">Drug-induced lupus</span>
				<br />
				<span>
					Certain medications (particularly when used for prolonged periods of
					time) can trigger an autoimmune reaction by the body that presents
					with symptoms similar to lupus.
				</span>
			</li>
			<li>
				<span className="font-bold">Neonatal lupus</span>
				<br />
				<span>
					This can affect newborn babies whose mother carries a specific lupus
					related antibody.
				</span>
			</li>
		</ol>
		<p>Some other important points of note about Lupus:</p>
		<ul>
			<li>
				Early detection and treatment is the key to a better health outcome and
				can usually lessen the progression and severity of the disease.
			</li>
			<li>Lupus affects mainly women</li>
			<li>
				Lupus is one of Australia’s least recognized major diseases. While lupus
				is widespread, awareness and accurate knowledge about it is lacking.
			</li>
			<li>
				More than 90% of lupus sufferers are women, mostly young women who are
				generally diagnosed between the ages of 15 to 45.
			</li>
			<li>
				Women of a non-Caucasian background are two to three times more at risk
				for lupus than Caucasians.
			</li>
		</ul>
		<h2>Serious consequences of Lupus</h2>
		<p>
			In lupus, the immune system, which is designed to protect against
			infection, instead attacks the body’s own tissues and organs with white
			blood cells and antibodies, causing inflammation in that area.
		</p>
		<p>
			It can affect any organ or system “head to toe” in the body, including the
			kidneys, brain, heart, lungs, blood, skin, and joints.
		</p>
		<p>
			Lupus is a leading cause of premature cardiovascular disease, kidney
			disease and stroke among young women.
		</p>
	</Layout>
)

export default InformationAboutLupus
